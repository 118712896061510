export default [
  {
    category: 'video-object-detection',
    header: ['modelLabels', ['Video Object Detection']],
    list: import('@/../public/labels/OBJ.json'),
  },
  {
    category: 'image-object-detection',
    header: ['modelLabels', ['Image Object Detection']],
    list: import('@/../public/labels/OBJ.json'),
  },
  {
    category: 'video-landmark-detection',
    header: ['modelLabels', ['Video Landmark Detection']],
    list: import('@/../public/labels/LAD.json'),
  },
  {
    category: 'image-landmark-detection',
    header: ['modelLabels', ['Image Landmark Detection']],
    list: import('@/../public/labels/LAD.json'),
  },
  {
    category: 'video-classification',
    header: ['modelLabels', ['Video Classification']],
    list: import('@/../public/labels/V-CLS.json'),
  },
  {
    category: 'image-classification',
    header: ['modelLabels', ['Image Classification']],
    list: import('@/../public/labels/I-CLS.json'),
  },
  {
    category: 'image-celebrity-detection',
    header: ['modelLabels', ['Celebrity Detection']],
    list: import('@/../public/labels/V-CEB.json'),
  },
  {
    category: 'video-celebrity-detection',
    header: ['modelLabels', ['Video Celebrity Detection']],
    list: import('@/../public/labels/V-CEB.json'),
  },
  {
    category: 'not-safe-for-work',
    header: ['modelLabels', ['Not Safe For Work']],
    list: import('@/../public/labels/NSFW.json'),
  },
];
