<template>
  <div
    id="labels"
    ref="elLabels"
  >
    <div class="heading">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="header" />
    </div>

    <dynamic-scroller
      ref="scroller"
      :items="filteredList"
      :min-item-size="46"
      class="scroller"
    >
      <template slot-scope="{ item, itemIndex, active }">
        <dynamic-scroller-item
          :item="item"
          :index="itemIndex"
          :active="active"
          :data-index="itemIndex"
          :data-active="active"
          class="scroll-item-container"
        >
          <div
            v-for="(text, textIndex) in item.list"
            :key="textIndex"
            class="scroll-item"
          >
            {{ text }}
          </div>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
    <button
      class="btn-scroll-top"
      @click="scrollToTop"
    >
      <font-awesome-icon
        icon="angle-up"
        class="icon"
      />
    </button>
  </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import labels from './labels';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default {
  name: 'Labels',
  components: {
    DynamicScroller,
    DynamicScrollerItem,
  },
  data() {
    return {
      header: '',
      list: [],
    };
  },
  computed: {
    filteredList() {
      const chunkSize = ['xs', 'sm'].includes(this.$screen.device) ? 2 : 3;
      const arrLength = this.list.length / chunkSize;
      return Array.from({ length: Math.ceil(arrLength) }, (v, i) => ({
        id: i,
        list: this.list.slice(i * chunkSize, i * chunkSize + chunkSize),
      }));
    },
  },
  watch: {
    $route() {
      this.loadLabels();
    },
  },
  async mounted() {
    this.$refs.elLabels.style.height = `${100 - (this.$refs.elLabels.getBoundingClientRect().top * 100) / this.$screen.height}vh`;
    this.loadLabels();
  },
  methods: {
    async loadLabels() {
      const currentCategory = labels.find((item) => item.category === this.$route.params.category);
      if (currentCategory) {
        this.header = this.$t(...currentCategory.header);
        const { default: list } = await currentCategory.list;
        this.list = list.sort();
      } else {
        this.$router.push('/page-not-found');
      }
    },
    scrollToTop() {
      this.$refs.scroller.scrollToItem(0);
    },
  },
};
</script>

<style lang="scss" scoped>
#labels {
  display: flex;
  flex-direction: column;
  padding: 0.40rem 0.46rem;
  background: url(~@/assets/images/background/labels-globe.svg)  right center no-repeat, $background-secondary;
  background-size: 70vh;
  background-attachment: fixed;
  overflow: hidden;

  .heading {
    font-size: 0.28rem;
    font-weight: 400;
  }

  .scroller {
    margin-top: 0.4rem;
    line-height: 0.36rem;

    .scroll-item {
      display: inline-flex;
      line-height: .36rem;
      width: 33.33%;

      @include tablet-and-less {
        width: 50%;
      }

      &::before {
        content:  "";
        display: inline-flex;
        background-color: $text-blue;
        height: .06rem;
        width: .06rem;
        min-width: .06rem;
        border-radius: 100%;
        margin-right: .1rem;
        margin-top: .15rem;
      }
    }
  }

  .btn-scroll-top {
    position: fixed;
    right: .47rem;
    bottom: .92rem;
    width: .56rem;
    height: .56rem;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 .14rem .32rem rgba(58, 113, 255, 0.19);
    color: $text-blue;
    border: none;
    font-size: .26rem;
  }

  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: .1rem;
  }

  *::-webkit-scrollbar {
    width: .07rem;
    background-color: #F5F5F5;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: .1rem;
    background-image: -webkit-gradient(linear,
                      left bottom,
                      left top,
                      color-stop(0.44, darken($text-blue, 10%)),
                      color-stop(0.86, $text-blue));
  }
}

/deep/ #app-nav {
  min-height: .8rem;
}
</style>
